import React, { useEffect, useState } from "react";
import "./ControlTags.css";
var objectPath = require("object-path");

export const ControlTags = ({
  name,
  label,
  placeholder = "Ingrese un tag",
  onChange = () => {},
  data,
  start,
  end,
  ...options
}) => {
  const value = data ? objectPath(data).get(name, "") : "";

  const [input, setInput] = useState("");
  const [tags, setTags] = useState(() => (value ? value.split(",") : []));
  const [isKeyReleased, setIsKeyReleased] = useState(false);

  useEffect(() => {
    onChange && onChange({ target: { name, value: tags.join(",") } });
  }, [tags]);

  const onKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = input.trim();

    if (
      (key === "," || key === "Enter") &&
      trimmedInput.length &&
      !tags.includes(trimmedInput)
    ) {
      e.preventDefault();
      setTags((prevState) => [...prevState, trimmedInput]);
      setInput("");
    }

    if (key === "Backspace" && !input.length && tags.length && isKeyReleased) {
      const tagsCopy = [...tags];
      const poppedTag = tagsCopy.pop();
      e.preventDefault();
      setTags(tagsCopy);
      setInput(poppedTag);
    }

    setIsKeyReleased(false);
  };

  const onKeyUp = () => {
    setIsKeyReleased(true);
  };
  const deleteTag = (index) => {
    setTags((prevState) => prevState.filter((tag, i) => i !== index));
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setInput(value);
  };

  return (
    <div className="control-tags mb-3 row mb-3 row w-100 d-flex flex-row">
      <label htmlFor={name} className="col-sm-4 col-form-label fst-italic">
        {label}
      </label>

      <div
        className={`container col-sm-8 d-flex flex-row p-0`}
        style={{ backgroundColor: "white" }}
      >
        <input
          value={input}
          placeholder={placeholder}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          onChange={handleInputChange}
          className="tag-input"
        />
        {start}
        {tags.map((tag, index) => (
          <div className="tag">
            {tag}
            <button onClick={() => deleteTag(index)}>x</button>
          </div>
        ))}
        {end}
      </div>
    </div>
  );
};
