import { useFilters } from "./hooks/useFilters"


export default function Filters() {

    const { filters, setFilters } = useFilters()

    const handleChangeRandomPrice = () => {
        setFilters({ ...filters, priceOrder: 0 })

    }
    const handleChangeMinPrice = () => {
        setFilters({ ...filters, priceOrder: 1 })
    }
    const handleChangeMaxPrice = () => {
        setFilters({ ...filters, priceOrder: 2 })
    }

    const handleChangeCategory = (event) => {
        setFilters(prevState => ({
            ...prevState,
            category: event.target.value
        }))
    }

    return (
        <aside className="filtros-container">
            <div className="filtro-precio">
                <h3 className="filtro-titulo">ORDENAR POR</h3>
                <ul>
                    <li > <input type="text" onClick={handleChangeRandomPrice} value="Aleatorio" readOnly /></li>
                    <li > <input type="text" onClick={handleChangeMinPrice} value="Precio - Menor a mayors" readOnly /></li>
                    <li > <input type="text" onClick={handleChangeMaxPrice} value="Precio - Mayor a menor" readOnly /></li>
                </ul>
            </div>
            <div className="categorias">
                <h3 className="filtro-titulo">CATEGORÍAS</h3>
                <ul>
                    <li > <input type="text" onClick={handleChangeCategory} value="Todos los productos" readOnly /></li>
                    <li > <input type="text" onClick={handleChangeCategory} value="Cuidados Esenciales" readOnly /></li>
                    <li > <input type="text" onClick={handleChangeCategory} value="Activos Específicos" readOnly /></li>
                    <li > <input type="text" onClick={handleChangeCategory} value="Tratamientos Antiage" readOnly /></li>
                    <li > <input type="text" onClick={handleChangeCategory} value="Tratamientos Específicos" readOnly /></li>
                    <li > <input type="text" onClick={handleChangeCategory} value="Suncare" readOnly /></li>
                    <li > <input type="text" onClick={handleChangeCategory} value="Productos Profesionales" readOnly /></li>
                </ul>

            </div>
        </aside>

    )
}
