import { useEffect, useRef, useState } from "react"
import MenuDesplegable from "./MenuDesplegable"
import { getAll } from "../../../services/abm";
import "./css/nav2.css"

export default function Nav2() {
    const [categorie, setCategories] = useState({ categories: [] })
    const componentMounted = useRef(true)

    useEffect(() => {

        getAll("categories")
            .then((res) => {
                if (componentMounted.current) {
                    const resCategorie = res
                    setCategories(resCategorie)
                    // console.log(resCategorie);
                }
            })
            .catch((err) => console.log(err));

        return () => { // This code runs when component is unmounted
            componentMounted.current = false; // (4) set it to false when we leave the page
        }
    }, [])

    const parentCategoryUnFiltered = categorie.categories?.filter(
        (category) => category.parentCategory === null
    );

    return (
        <nav className="nav-container">
            <ul className="menu-horizontal">
                {parentCategoryUnFiltered.map(parentCat => (
                    <li className="contenedor-relative" key={parentCat.id}>
                        <a href="#">{parentCat.name}</a>
                        <MenuDesplegable categorie={categorie} parentCategoryUnFiltered={parentCat}></MenuDesplegable>
                    </li>
                ))}
            </ul>

        </nav>
    )
}
