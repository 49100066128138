import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { abm } from "../../../services/abm";
import { set } from "object-path-immutable";

const AddCategoryModal = (props) => {
  const { categories2, categories3, categories, show, close, add, refresh } = props;

  const [newCategory, setNewCategory] = useState("");
  const [sectors, setSectors] = useState([]);
  const [newIsPublic, setNewIsPublic] = useState(false);
  const [newIsProfessional, setNewIsProfessional] = useState(false);
  const [showSelect, setShowSelect] = useState(false);
  const [showSelect3, setShowSelect3] = useState(false);
  const [showSelect4, setShowSelect4] = useState(false);
  const [selectValue, setSelectValue] = useState(null);
  const [selectSectorValue, setSelectSectorValue] = useState(null);

  useEffect(() => {
    console.log(categories);
  }, [categories])

  const handleNameChange = (event) => {
    setNewCategory(event.target.value);
  };

  const handleSelectChange = (event) => {
    setSelectValue(event.target.value !== "" ? event.target.value : null);
  };

  const handleSelectSectorChange = (event) => {
    setSelectSectorValue(event.target.value !== "" ? event.target.value : null);
  };

  const handleIsPublicChange = (event) => {
    setNewIsPublic(!newIsPublic);
  };

  const handleIsProfessionalChange = (event) => {
    setNewIsProfessional(!newIsProfessional);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (event.target.value !== "") add(newCategory, selectValue, selectSectorValue, newIsPublic, newIsProfessional);
    setNewCategory("");
    setShowSelect(false);
    setSelectSectorValue(null);
    setNewIsPublic(false);
    setNewIsProfessional(false);
    setSelectValue(null);
    close();
  };

  const handleClose = () => {
    setShowSelect(false);
    close();
  };

  useEffect(() => {
    abm("sectors")
      .getAll()
      .then((data) => {
        setSectors(
          data.results.map((item) => ({ value: item.name, label: item.name }))
        );
      });
  }, []);
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {`Nueva ${showSelect ? "subcategoría" : "categoría"}`}
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group controlId="name">
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={newCategory}
              onChange={handleNameChange}
              required
            />
          </Form.Group>

          <Form.Group className="mt-3" controlId="sectorId">

            <Form.Select
              id="selectSector"
              value={selectSectorValue || ""}
              onChange={handleSelectSectorChange}
            >
              <option value="">Seleccioná el sector</option>
              {sectors.map((sector) => {
                return (
                  <option key={sector.value} value={sector.value}>
                    {sector.value}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mt-3" controlId="isPublic">
            <Form.Check
              label="Es Publico"
              value={newIsPublic}
              onChange={handleIsPublicChange}
            />
          </Form.Group>

          <Form.Group className="mt-3" controlId="isProfessional">
            <Form.Check
              label="Es Profesional"
              value={newIsProfessional}
              onChange={handleIsProfessionalChange}
            />
          </Form.Group>

          <Form.Group className="mt-3" controlId="checkSubcategoria">
            <Form.Check
              type="checkbox"
              label="Crear como categoria secundaria"
              onChange={() => {
                setShowSelect(!showSelect);
                setSelectValue(null);
              }}
            />
          </Form.Group>

          <Form.Group className="mt-3" controlId="checkCategoriaTerciaria">
            <Form.Check
              type="checkbox"
              label="Crear como categoria terciaria"
              onChange={() => {
                setShowSelect3(!showSelect3);
                setSelectValue(null);
              }}
            />
          </Form.Group>

          <Form.Group className="mt-3" controlId="checkCategoriaCuaternaria">
            <Form.Check
              type="checkbox"
              label="Crear como categoria cuaternaria"
              onChange={() => {
                setShowSelect4(!showSelect4);
                setSelectValue(null);
              }}
            />
          </Form.Group>

          {showSelect && (
            <Form.Group className="mb-3">
              <Form.Label htmlFor="selectCategories"></Form.Label>
              <Form.Select
                id="selectCategories"
                value={selectValue || ""}
                onChange={handleSelectChange}
              >
                <option value="">Seleccioná la categoría padre</option>
                {categories.map((category) => {
                  return (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
          )}
          {showSelect3 && (
            <Form.Group className="mb-3">
              <Form.Label htmlFor="selectCategories"></Form.Label>
              <Form.Select
                id="selectCategories"
                value={selectValue || ""}
                onChange={handleSelectChange}
              >
                <option value="">Seleccioná la categoría padre</option>
                {categories2.map((category) => {
                  return (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
          )}
          {showSelect4 && (
            <Form.Group className="mb-3">
              <Form.Label htmlFor="selectCategories"></Form.Label>
              <Form.Select
                id="selectCategories"
                value={selectValue || ""}
                onChange={handleSelectChange}
              >
                <option value="">Seleccioná la categoría padre</option>
                {categories3.map((category) => {
                  return (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            type="submit"
            onClick={refresh}
            disabled={
              (!newCategory && showSelect && selectValue === null) ||
              (!newCategory && showSelect && selectValue !== null) ||
              (newCategory && showSelect && selectValue === null) ||
              (!newCategory && !showSelect)
            }
          >
            Agregar
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddCategoryModal;
