
import SvgNext from "../assets/Next";
import "./css/menuDesplegable2.css"
import { useEffect, useRef, useState } from "react"

export default function MenuDesplegable({ categorie, parentCategoryUnFiltered }) {
    const parentCategoryName = parentCategoryUnFiltered.name

    const childCategories = categorie.categories?.filter(
        (category) => category.parentCategory !== null
            && category.parentCategory.parentCategory === null
            && category.parentCategory.name === parentCategoryName
    );
    const Categories3 = categorie.categories?.filter(
        (category) => category.parentCategory !== null
            && category.parentCategory[0].parentCategory !== null
            && category.parentCategory[0].parentCategory.name === parentCategoryName
    );

    return (
        <div>
            <div className="triangulo-equilatero"></div>
            <ul className="menu-vertical">
                <h2 className="menu-vertical-title">#IDRAETDERMOPURITY</h2>

                {childCategories?.map((parentCat, index) => {
                    return (
                        <li className="seccion" key={index}>
                            <a className="category-section"> {parentCat.name} <SvgNext width={14} fill={"var(--white-color)"}></SvgNext></a>
                            <ul className="menu-final">
                                <ul>
                                    <h2 className="parent-category"> {parentCat.name} </h2>
                                    <div className="grid-container">
                                        {/* {categoriesByParent[parentCat.id]?.map((child, childIndex) => */}
                                        {Categories3?.map((child, childIndex) => {
                                            return (
                                                <div key={childIndex}>
                                                    <h3 className="seccion">
                                                        {child.parentCategory.id === parentCat.id ? child.name : ""}
                                                    </h3>
                                                    {/* {Categories3?.map((child3, child3Index) => {
                                                        return (
                                                            child3.parentCategory.id === child.id ? (
                                                                <ul key={child3Index}>
                                                                    <li className="seccion">{child3.name}</li>
                                                                </ul>
                                                            ) : null
                                                        );
                                                    })} */}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </ul>
                            </ul>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}
